import React, { ComponentProps } from 'react';

export default function DTSLogoSmall(props: ComponentProps<'svg'>) {
  return (
    <svg width="63" height="20" viewBox="0 0 63 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.34082 2.52588H13.5408C18.9513 2.52588 22.2566 5.11535 22.2566 9.99956C22.2566 14.968 18.9303 17.4732 13.5408 17.4732H6.34082V2.52588ZM13.5619 13.8101C16.5513 13.8101 17.9619 12.6101 17.9619 9.99956C17.9619 7.32588 16.4882 6.18904 13.5619 6.18904H10.6566V13.8101H13.5619Z"
        fill="white"
      />
      <path
        d="M29.0134 6.18904H22.3818V2.52588H39.9818V6.21009H33.3292V17.4943H29.0134V6.18904Z"
        fill="white"
      />
      <path
        d="M40.0887 12.5467H44.6993C44.6572 13.5572 46.0466 14.1467 48.4256 14.1467C51.2887 14.1467 52.9729 13.6835 52.9729 12.8625C52.9729 10.294 40.5519 14.1046 40.5519 7.26246C40.5519 4.18878 43.7519 2.16772 48.5519 2.16772C53.4782 2.16772 56.8466 4.27299 56.8887 7.45194H52.2782C52.3203 6.44141 51.0571 5.85194 48.9308 5.85194C46.4045 5.85194 44.9098 6.31509 44.9098 7.13615C44.9098 9.87299 57.3308 6.33615 57.3308 12.7361C57.3308 15.8098 53.9414 17.8309 48.8256 17.8309C43.5414 17.8309 40.0256 15.7256 40.0887 12.5467Z"
        fill="white"
      />
      <path
        d="M1.45263 10C1.45263 13.4105 2.90526 16.6105 5.47368 19.0316L4.50526 19.9789C1.49474 17.1368 0 13.8105 0 10C0 6.18947 1.49474 2.86316 4.48421 0L5.45263 0.947369C2.90526 3.38947 1.45263 6.58947 1.45263 10Z"
        fill="white"
      />
      <path
        d="M57.3691 0.947368L58.3376 0C61.327 2.84211 62.8218 6.18947 62.8218 10C62.8218 13.8105 61.327 17.1368 58.3376 20L57.3691 19.0526C59.9376 16.6316 61.3902 13.4316 61.3902 10.0211C61.3902 6.61053 59.9165 3.38947 57.3691 0.947368Z"
        fill="white"
      />
    </svg>
  );
}

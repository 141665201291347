import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Button from '../shared/Button';
import DTSLogoSmall from '../layout/icons/DTSLogoSmall';
import DTSLogo from '../layout/icons/DTSLogo';

const NavBar = ({ loginButton = true }) => {
  const router = useRouter();
  const [hideLogin, setHideLogin] = useState(false);
  useEffect(() => {
    setHideLogin(router.pathname.startsWith('/onboarding'));
  }, [router.pathname]);
  return (
    <div className="flex justify-between items-center pt-[20px] xs:pt-[32px] px-[20px] xs:px-[64px] pb-[36px] z-40 fixed top-0 left-0 right-0 bg-black">
      <div className="cursor-pointer">
        <a className="contents" href="https://deathtothestockphoto.com/">
          <div className="xs:hidden">
            <DTSLogoSmall />
          </div>
          <div className="hidden xs:block">
            <DTSLogo />
          </div>
        </a>
      </div>
      {!hideLogin && (
        <Link
          href="/login"
          className={clsx([
            'hidden xs:block',
            loginButton ? 'contents' : 'opacity-0 pointer-events-none',
          ])}
        >
          <Button type="secondary">Log In</Button>
        </Link>
      )}
      <div className="xs:hidden">
        <a href="/login">
          <span className="text-[14px] text-[#FF3E00]">LOGIN</span>
        </a>
      </div>
    </div>
  );
};

export default NavBar;
